import { useInvestorBoard } from "../../hooks/useInvestorBoard";
import { Box, LinearProgress, Typography } from "@mui/material";

interface BlockchainDataBlockProps {
    poolId: string | undefined;
}
export const BlockchainDataBlock = ({ poolId }: BlockchainDataBlockProps) => {
    const { usePoolBlockchainData } = useInvestorBoard();

    const { data, isLoading, error } = usePoolBlockchainData(poolId || "");

    if (isLoading) {
        return <LinearProgress />;
    }

    if (error) {
        return <div>Error Loading Data</div>;
    }

    return (
        <Box sx={{ display: "flex", gap: "12px", flexDirection: "column" }}>
            <Typography variant="body2" color="text.secondary">
                Pool Size: {data?.poolSize} {data?.symbol}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                Total Deposited: {data?.totalDeposited} {data?.symbol}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                Min Investment Size: {data?.minInvestmentSize} {data?.symbol}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                Lockup Period: {data?.lockupPeriod} day(s)
            </Typography>
        </Box>
    );
};
