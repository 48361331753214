import { PoolBlockchainAccountValues, PoolBlockchainValues, PoolStatus } from "../../../../types/pools";
import { Address, ONE_DAY_TIME_UNIT } from "../../../../web3/constants";
import { ethers } from "ethers";
import abiPoolFactory from "../../../../contracts/abi/PoolFactory.json";
import abiIERC20MetaDataFactory from "../../../../contracts/abi/IERC20Metadata.json";
import abiPool from "../../../../contracts/abi/Pool.json";
import abiBlendedPool from "../../../../contracts/abi/BlendedPool.json";
import { formatBigInt, transformNumber } from "../../../../utils";
import { useQuery } from "react-query";
import { useWeb3React } from "@web3-react/core";
import { fromAscii } from "web3-utils";
import { useEffect } from "react";
import { dummyReactSyntheticEvent, PoolPageTabs } from "../../../../constants";

export function useInvestorBoard() {
    const { active, account, library } = useWeb3React();
    const CONTRACT_POOL_FACTORY = process.env.REACT_APP_CONTRACT_POOL_FACTORY!;
    const CONTRACT_USDC = process.env.REACT_APP_CONTRACT_USDC!;
    const BP_ADDRESS = process.env.REACT_APP_CONTRACT_BLENDED_POOL!;

    const provider = library;
    const poolFactory = new ethers.Contract(CONTRACT_POOL_FACTORY, abiPoolFactory, provider);
    const IERC20MetaData = new ethers.Contract(CONTRACT_USDC, abiIERC20MetaDataFactory, provider);
    let signer: any = null;

    if (provider) {
        signer = provider.getSigner();
    }

    const getPoolBlockchainData = async (poolId: string): Promise<PoolBlockchainValues> => {
        try {
            if (poolId) {
                const address = await poolFactory.pools(poolId);

                if (address === Address.Null) {
                    return {
                        poolStatus: PoolStatus.Draft,
                    } as PoolBlockchainValues;
                }
                // Create Pool contract object
                const pool = new ethers.Contract(address, abiPool, provider);

                if (!pool) {
                    return {
                        poolStatus: PoolStatus.NotExist,
                    } as PoolBlockchainValues;
                }

                const [poolInfo, decimals, poolState, symbol, totalSupply] = await Promise.all([
                    pool.poolInfo(),
                    pool.decimals(),
                    pool.poolState(),
                    IERC20MetaData.symbol(),
                    // total supply of the Helios tokens serves as the total deposited amount
                    pool.totalSupply(),
                ]);

                let poolStatus: PoolStatus;
                const { lockupPeriod, investmentPoolSize, minInvestmentAmount } = poolInfo;

                switch (poolState) {
                    case 0:
                        poolStatus = PoolStatus.Active;
                        break;
                    case 1:
                        poolStatus = PoolStatus.Closed;
                        break;
                    default:
                        poolStatus = PoolStatus.Error;
                        break;
                }

                const poolSize = formatBigInt(investmentPoolSize, decimals);
                const minInvestmentSize = formatBigInt(minInvestmentAmount, decimals);
                const totalDeposited = formatBigInt(totalSupply, decimals);

                return {
                    poolId,
                    poolAddress: address,
                    poolSize,
                    minInvestmentSize,
                    lockupPeriod: ethers.toNumber(BigInt(lockupPeriod)) / ONE_DAY_TIME_UNIT,
                    totalDeposited,
                    symbol,
                    poolStatus,
                };
            }

            return {
                poolStatus: PoolStatus.NotExist,
            } as PoolBlockchainValues;
        } catch (error) {
            return {
                poolStatus: PoolStatus.Error,
            } as PoolBlockchainValues;
        }
    };
    const getAccountPoolBlockchainData = async (poolId: string): Promise<PoolBlockchainAccountValues> => {
        if (active && signer && poolId) {
            try {
                const address = await poolFactory.pools(poolId);
                if (address === Address.Null) {
                    return {} as PoolBlockchainValues;
                }
                // Create Pool contract object
                const pool = new ethers.Contract(address, abiPool, signer);
                if (!pool) {
                    return {} as PoolBlockchainValues;
                }

                const [yearnedYield, decimals, symbol, balanceOf] = await Promise.all([
                    pool.yields(BP_ADDRESS),
                    pool.decimals(),
                    pool.symbol(),
                    pool.balanceOf(BP_ADDRESS),
                ]);
                const availableToWithdraw = await pool.unlockedToWithdraw(BP_ADDRESS);
                const unlockDate = await pool.getHolderUnlockDate(BP_ADDRESS);

                return {
                    yearnedYield: formatBigInt(yearnedYield, decimals),
                    availableToWithdraw: formatBigInt(availableToWithdraw, decimals) || 1,
                    investedAmount: formatBigInt(balanceOf, decimals),
                    isInvested: BigInt(balanceOf) > BigInt(0),
                    accountPoolBalance: formatBigInt(balanceOf, decimals) + formatBigInt(yearnedYield, decimals),
                    symbol,
                    decimals,
                    unlockDate: new Date(ethers.toNumber(unlockDate) * 1000).toLocaleDateString(),
                } as PoolBlockchainAccountValues;
            } catch (error) {
                console.log("Pool data fetching Error:", error);
                return {} as PoolBlockchainAccountValues;
            }
        } else {
            return {} as PoolBlockchainAccountValues;
        }
    };

    const usePoolBlockchainData = (poolId: string) => {
        return useQuery(["poolBlockchainData", poolId], () => getPoolBlockchainData(poolId), {
            staleTime: 1000 * 60 * 5, // data will be considered stale after 5 minutes
            cacheTime: 1000 * 60 * 30, // data will be cached for 30 minutes
        });
    };
    const useAccountPoolBlockchainData = (poolId: string) => {
        return useQuery(["accountPoolBlockchainData", poolId], () => getAccountPoolBlockchainData(poolId), {
            staleTime: 1000 * 60 * 10, // data will be considered stale after 5 minutes
            cacheTime: 1000 * 60 * 60, // data will be cached for 30 minutes
        });
    };

    return {
        usePoolBlockchainData,
        useAccountPoolBlockchainData,
        getPoolBlockchainData,
        getAccountPoolBlockchainData,
    };
}
