import {
    Alert,
    Box,
    Button,
    ButtonGroup,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";
import { Pool, PoolStatus } from "../../../../../types/pools";
import { BlockchainDataBlock } from "./BlockchainDataBlock";
import { AccountData } from "./AccountData";
import placeholderImage from "../../../../../assets/placeholder-image.png";
import React, { useEffect, useState } from "react";
import { AppNumberField } from "../../../../../components/AppFields";
import { useTransactionsBP } from "../../hooks/useTransactionsBP";
import { TransactionStatus, TransactionStatusValues } from "../../../../Pools/Forms/FundsManageForm/types";
import { useQuery } from "react-query";
import { useInvestorBoard } from "../../hooks/useInvestorBoard";
import { AppButton } from "../../../../../components";

interface PoolCardProps {
    pool: Pool;
}

export const PoolCard = ({ pool }: PoolCardProps) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [transactionAmount, setTransactionAmount] = useState(0);
    const [isInvestmentDisabled, setIsInvestmentDisabled] = useState(true);
    const [isWithdrawFundsDisabled, setIsWithdrawFundsDisabled] = useState(true);
    const [transactionStatus, setTransactionStatus] = useState<TransactionStatusValues | null>(null);
    const { getAccountPoolBlockchainData, getPoolBlockchainData } = useInvestorBoard();
    const { depositToOpenPool, withdrawFromPool, withdrawYieldFromPool } = useTransactionsBP(pool.id as string);
    const [transactionLoading, setTransactionLoading] = useState(false);
    const [transactionType, setTransactionType] = useState<string>("");

    const { data: accountBlockchainData, refetch: refetchAccountBlockchainData } = useQuery({
        queryKey: ["accountPoolBlockchainData", pool.id],
        queryFn: () => getAccountPoolBlockchainData(pool.id as string),
        enabled: false,
    });

    const { data: poolBlockchainData, refetch: refetchPoolBlockchainData } = useQuery({
        queryKey: ["poolBlockchainData", pool.id],
        queryFn: () => getPoolBlockchainData(pool.id as string),
        enabled: false,
    });

    const isAvailableToWithdrawFunds =
        accountBlockchainData?.availableToWithdraw !== undefined && accountBlockchainData?.availableToWithdraw > 0;
    const isAvailableToWithdrawYield =
        accountBlockchainData?.yearnedYield !== undefined && accountBlockchainData?.yearnedYield > 0;

    const initiateTransaction = (type: string) => {
        setTransactionType(type);
        setIsDialogOpen(true);
    };
    const handleInvest = async () => {
        setTransactionLoading(true);

        const response = await depositToOpenPool(transactionAmount);
        setTransactionStatus(response);

        if (response.status === TransactionStatus.Success) {
            console.log("Refetching data...");
            await refetchAccountBlockchainData();
            await refetchPoolBlockchainData();
        }

        setTimeout(() => {
            setTransactionStatus(null);
            setTransactionLoading(false);
            setIsDialogOpen(false);
            setTransactionAmount(0);
        }, 5000);
    };

    const handleWithdrawFunds = async () => {
        setTransactionLoading(true);

        const response = await withdrawFromPool(transactionAmount);
        setTransactionStatus(response);

        if (response.status === TransactionStatus.Success) {
            console.log("Refetching data...");
            await refetchAccountBlockchainData();
            await refetchPoolBlockchainData();
        }

        setTimeout(() => {
            setTransactionStatus(null);
            setTransactionLoading(false);
        }, 5000);
    };

    const handleWithdrawYield = async () => {
        setTransactionLoading(true);

        const response = await withdrawYieldFromPool();
        setTransactionStatus(response);

        if (response.status === TransactionStatus.Success) {
            console.log("Refetching data...");
            await refetchAccountBlockchainData();
            await refetchPoolBlockchainData();
        }

        setTimeout(() => {
            setTransactionStatus(null);
            setTransactionLoading(false);
        }, 5000);
    };

    useEffect(() => {
        if (
            poolBlockchainData !== undefined &&
            accountBlockchainData !== undefined &&
            poolBlockchainData?.totalDeposited !== undefined
        ) {
            if (transactionType === "invest") {
                if (
                    poolBlockchainData?.minInvestmentSize > transactionAmount ||
                    transactionAmount === undefined ||
                    transactionAmount === 0 ||
                    transactionAmount + poolBlockchainData?.totalDeposited > poolBlockchainData?.poolSize
                ) {
                    setIsInvestmentDisabled(true);
                } else {
                    setIsInvestmentDisabled(false);
                }
            } else {
                if (
                    (accountBlockchainData?.availableToWithdraw !== undefined &&
                        accountBlockchainData?.availableToWithdraw < transactionAmount) ||
                    transactionAmount === 0 ||
                    transactionAmount === undefined
                ) {
                    setIsWithdrawFundsDisabled(true);
                } else {
                    setIsWithdrawFundsDisabled(false);
                }
            }
        }
    }, [transactionAmount]);

    return (
        <>
            <Card sx={{ flexBasis: 325, flexGrow: 1, border: "1px solid grey", maxWidth: "400px" }}>
                <CardContent>
                    <div
                        style={{
                            position: "relative",
                            marginBottom: "12px",
                        }}
                    >
                        <img
                            src={
                                pool.images && pool.images.length > 0
                                    ? pool.images[0]?.urls?.["original"]
                                    : placeholderImage
                            }
                            alt={pool.name ?? ""}
                            style={{
                                width: "100%",
                                height: "200px",
                                objectFit: "cover",
                                borderRadius: "8px",
                                objectPosition: "center",
                            }}
                        />
                        <Typography
                            variant="subtitle1"
                            component="div"
                            sx={{
                                position: "absolute",
                                top: "5px",
                                right: "5px",
                                backgroundColor: "#0c0f30cc",
                                borderRadius: "8px",
                                padding: "5px",
                            }}
                        >
                            {pool.status}
                        </Typography>
                        <ButtonGroup
                            variant="outlined"
                            aria-label="Basic button group"
                            color="primary"
                            sx={{
                                position: "absolute",
                                bottom: "5px",
                                left: "0",
                                right: "0",
                                margin: "auto",
                                height: "60px",
                                backgroundColor: "#0c0f30cc",
                                width: "fit-content",
                            }}
                        >
                            <Button
                                onClick={() => initiateTransaction("invest")}
                                disabled={pool.status !== PoolStatus.Active}
                            >
                                Invest
                            </Button>
                            <Button
                                onClick={() => initiateTransaction("withdrawFunds")}
                                disabled={!isAvailableToWithdrawFunds}
                            >
                                Withdraw Funds
                            </Button>
                            <Button disabled={!isAvailableToWithdrawYield} onClick={handleWithdrawYield}>
                                Withdraw Yield
                            </Button>
                        </ButtonGroup>
                    </div>
                    <Typography variant="h5" component="div">
                        {pool.name}
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "column",
                        }}
                    >
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                                marginBottom: "12px",
                            }}
                        >
                            Region: {pool.region}
                        </Typography>
                        <BlockchainDataBlock poolId={pool.id} />
                        <AccountData poolId={pool.id} />
                    </Box>
                </CardContent>
            </Card>

            {/* Dialog to enter a value */}
            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                >
                    <div>{transactionType === "invest" && "Invest"}</div>
                    <div>{transactionType === "withdrawFunds" && `Withdraw Funds`}</div>
                    <Typography variant="body2">
                        {transactionType === "withdrawFunds" &&
                            `Available to withdraw: ${accountBlockchainData?.availableToWithdraw} ${
                                poolBlockchainData && poolBlockchainData.symbol
                            }`}
                    </Typography>
                </DialogTitle>
                <DialogContent
                    sx={{
                        width: "400px",
                        height: "auto",
                    }}
                >
                    <AppNumberField
                        id="transactionAmount"
                        type="number"
                        required
                        value={transactionAmount}
                        defaultValue={0}
                        setValue={(id, value) => setTransactionAmount(value)}
                        sx={{
                            width: "100%",
                            marginTop: "12px",
                        }}
                    />
                    {transactionStatus && (
                        <Alert
                            severity={transactionStatus?.status}
                            sx={{
                                marginTop: "12px",
                                "& .MuiAlert-message": {
                                    whiteSpace: "pre-wrap",
                                },
                            }}
                        >
                            {transactionStatus?.message?.slice(0, 100)}
                        </Alert>
                    )}
                </DialogContent>
                <DialogActions
                    sx={{
                        paddingLeft: "19px",
                        paddingRight: "19px",
                    }}
                >
                    <AppButton onClick={() => setIsDialogOpen(false)}>Cancel</AppButton>
                    {transactionType === "invest" && (
                        <AppButton
                            onClick={handleInvest}
                            disabled={isInvestmentDisabled || transactionLoading}
                            loading={transactionLoading}
                        >
                            Invest
                        </AppButton>
                    )}
                    {transactionType === "withdrawFunds" && (
                        <AppButton
                            onClick={handleWithdrawFunds}
                            disabled={transactionLoading || isWithdrawFundsDisabled}
                            loading={transactionLoading}
                        >
                            Withdraw Funds
                        </AppButton>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
};
