import { useInvestorBoard } from "../../hooks/useInvestorBoard";
import { Box, LinearProgress, Typography } from "@mui/material";

interface AccountDataProps {
    poolId: string | undefined;
}

export const AccountData = ({ poolId }: AccountDataProps) => {
    const { useAccountPoolBlockchainData } = useInvestorBoard();
    const { data, isLoading, error } = useAccountPoolBlockchainData(poolId || "");

    if (isLoading) {
        return <LinearProgress />;
    }

    if (error) {
        return <div>Error Loading Data</div>;
    }

    return (
        <Box sx={{ display: "flex", gap: "12px", flexDirection: "column", marginTop: "12px" }}>
            <Typography variant="body2" color="text.secondary">
                BP balance: {data?.accountPoolBalance || 0} {data?.symbol}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                BP Invested: {data?.investedAmount || 0} {data?.symbol}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                Available To Withdraw: {data?.availableToWithdraw || 0} {data?.symbol}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                Yearned Yield: {data?.yearnedYield || 0} {data?.symbol}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                Unlock Date: {data?.unlockDate || 0}
            </Typography>
        </Box>
    );
};
